var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "panel-item", class: { actived: _vm.actived } },
    [
      _c("img", { staticClass: "display-icon", attrs: { src: _vm.url } }),
      _vm._v(" "),
      _c("div", { staticClass: "display-data" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                ![null, undefined, ""].includes(_vm.label) ? _vm.label : "-"
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.suffix))]),
      ]),
      _vm._v(" "),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.actived,
            expression: "actived",
          },
        ],
        staticClass: "mini-arrow",
        attrs: { src: require("@/assets/box-arrow.png") },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }