<template>
  <div class="content-card">
    <div class="header-bar">
      <div class="flex-between">
        <div class="content-card-title" v-overflow-tooltip>
          {{ data.pileName }}
        </div>
        <div
          class="content-card-tag"
          :style="{
            color: chargePlotStatColor(data.pileStat),
            borderColor: chargePlotStatColor(data.pileStat),
          }"
          v-if="miniTagFormatter(data)"
        >
          {{ miniTagFormatter(data) }}
        </div>
      </div>
      <div class="flex-between">
        <div class="content-sub-title">{{ data.connectorName }}</div>
        <div class="content-sub-title-suffix">{{ data.connectorCode }}</div>
      </div>
    </div>
    <div
      class="content-container"
      :style="{
        background: connectorStatFormat.bgColor,
      }"
    >
      <div class="progress-container">
        <el-progress
          type="circle"
          :percentage="data.connectorStat === 3 ? data.soc || 0 : 0"
          :width="50"
          :stroke-width="4"
          :define-back-color="connectorStatFormat.progressColor"
          :color="connectorStatFormat.color"
          :text-color="connectorStatFormat.color"
          :format="() => hasChargePowerFormatter(data)"
        ></el-progress>
        <svg-icon
          v-if="data.connectorStat === 3"
          icon-class="flash"
          class="right-bottom-icon"
        />
      </div>
      <div style="display: flex;gap: 4px;">
        <div class="progress-text" v-if="data.connectorStat === 3">
          {{
            data.pileType === 0
              ? (data.hasChargePower || '-') + '度'
              : (data.soc || '-') + '%'
          }}
        </div>
        <div
          class="remainder-tips"
          :style="{
            background: connectorStatFormat.textBgColor,
            color: connectorStatFormat.color,
          }"
        >
          {{
            (data.duration
              ? data.connectorStat === 3 && data.pileType === 0
                ? '充电'
                : gunDurationStatus[data.connectorStat]
              : '') + data.durationStr
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import {
  findLabelByValue,
  findValue,
  CHARGE_PLOT_STAT,
  CONNECTOR_STAT,
} from '@/utils/const';
let props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

let pileTypeDic = {
  0: '交流',
  1: '直流',
};
// 电桩状态(0:在线 1:离线 2:停用)
let pileStatusDic = {
  0: '在线',
  1: '离线',
  2: '停用',
};
// (0:离线 1:故障 2:空闲 3:充电 4:占用 5:停用)
let gunDurationStatus = ref({
  0: '离线',
  1: '故障',
  2: '空闲',
  3: '剩余',
  4: '占用',
  5: '停用',
});
function miniTagFormatter(row) {
  let group = [];

  if (Object.keys(pileTypeDic).includes(row.pileType + '')) {
    group.push(pileTypeDic[row.pileType]);
  }

  if (Object.keys(pileStatusDic).includes(row.pileStat + '')) {
    group.push(pileStatusDic[row.pileStat]);
  }
  return group.join(' | ');
}

function chargePlotStatColor(val) {
  return findLabelByValue(CHARGE_PLOT_STAT, val, 'color');
}

let connectorStatFormat = computed(() => {
  if (props.data.pileStat === 2) {
    return findValue(CONNECTOR_STAT, 999);
  }
  return findValue(CONNECTOR_STAT, props.data.connectorStat);
});
let hasChargePowerFormatter = (data) => {
  // 交流
  // return data.connectorStat === 3
  //   ? data.pileType === 0
  //     ? (data.hasChargePower || '-') + '度'
  //     : (data.soc || '-') + '%'
  //   : connectorStatFormat.value.label;
  return connectorStatFormat.value.label;
};
</script>

<style lang="scss" scoped>
.content-card {
  display: flex;
  min-width: 200px;
  // max-width: 240px;
  padding: 16px 12px 12px 12px;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: var(--PC-, #fff);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in;
  border: 1px solid transparent;
  z-index: 1;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    border: 1px solid rgba(5, 124, 152, 0.65);
    background: var(--PC-, #fff);
    box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.12);
  }
  .content-card-title {
    overflow: hidden;
    color: var(--PC-, #1d2129);
    text-overflow: ellipsis;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
  }
  .content-card-tag {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    border: 1px solid var(--PC-, #08ab67);
    color: var(--PC-, #08ab67);
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-wrap: nowrap;
  }
  .content-sub-title {
    color: var(--PC-, #4e5969);
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .content-sub-title-suffix {
    overflow: hidden;
    color: var(--PC-, #4e5969);
    text-overflow: ellipsis;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.header-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.content-container {
  display: flex;
  padding-top: 16px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(5, 124, 152, 0.04);
}
.flex-stretch {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.progress-container {
  position: relative;
  ::v-deep .el-progress__text {
    font-size: 14px !important;
    font-weight: 500;
  }
  .right-bottom-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 24px;
    flex-shrink: 0;
  }
}
.remainder-tips {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  background: rgba(5, 124, 152, 0.08);
  color: var(--PC-, #057c98);
  text-align: center;
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.progress-text {
  color: var(--PC-, #057c98);
  text-align: center;
  -webkit-text-stroke-width: 0.5;
  -webkit-text-stroke-color: #fff;
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
