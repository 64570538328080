var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "car-info-card" }, [
    _c("img", { staticClass: "corner-mark", attrs: { src: _vm.bgIcon } }),
    _vm._v(" "),
    _c("div", { staticClass: "car-info-card-label" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "car-info-card-content" }, [
      _c(
        "div",
        {
          directives: [
            { name: "overflow-tooltip", rawName: "v-overflow-tooltip" },
          ],
          staticClass: "car-info-card-status ellipsis",
          style: { color: _vm.color },
        },
        [_vm._v("\n      " + _vm._s(_vm.status || "-") + "\n    ")]
      ),
      _vm._v(" "),
      _vm.showTag
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                gap: "4px",
                "min-height": "19px",
              },
            },
            [
              _vm.overTimes > 0
                ? _c(
                    _setup.PlainTag,
                    {
                      attrs: {
                        color: "rgba(223, 55, 55, 1)",
                        bgColor: "rgba(223, 55, 55, 0.06)",
                      },
                    },
                    [_vm._v("\n        过高" + _vm._s(_vm.overTimes) + "次")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.underTimes > 0
                ? _c(
                    _setup.PlainTag,
                    {
                      attrs: {
                        color: "rgba(247, 167, 13, 1)",
                        bgColor: "rgba(247, 167, 13, 0.06)",
                      },
                    },
                    [_vm._v("\n        过低" + _vm._s(_vm.underTimes) + "次")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.overCurrentTimes > 0
                ? _c(
                    _setup.PlainTag,
                    {
                      attrs: {
                        color: "rgba(223, 55, 55, 1)",
                        bgColor: "rgba(223, 55, 55, 0.06)",
                      },
                    },
                    [
                      _vm._v(
                        "\n        过流" + _vm._s(_vm.overCurrentTimes) + "次"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.unTrustTimes > 0
                ? _c(
                    _setup.PlainTag,
                    {
                      attrs: {
                        color: "rgba(247, 167, 13, 1)",
                        bgColor: "rgba(247, 167, 13, 0.06)",
                      },
                    },
                    [
                      _vm._v(
                        "\n        不可信" + _vm._s(_vm.unTrustTimes) + "次"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }