<template>
  <div class="car-info-card">
    <img :src="bgIcon" class="corner-mark" />
    <div class="car-info-card-label">{{ label }}</div>
    <div class="car-info-card-content">
      <div
        class="car-info-card-status ellipsis"
        v-overflow-tooltip
        :style="{ color }"
      >
        {{ status || '-' }}
      </div>
      <div style="display: flex; gap: 4px;min-height: 19px;" v-if="showTag">
        <PlainTag
          v-if="overTimes > 0"
          color="rgba(223, 55, 55, 1)"
          bgColor="rgba(223, 55, 55, 0.06)"
        >
          过高{{ overTimes }}次</PlainTag
        >
        <PlainTag
          v-if="underTimes > 0"
          color="rgba(247, 167, 13, 1)"
          bgColor="rgba(247, 167, 13, 0.06)"
        >
          过低{{ underTimes }}次</PlainTag
        >
        <PlainTag
          v-if="overCurrentTimes > 0"
          color="rgba(223, 55, 55, 1)"
          bgColor="rgba(223, 55, 55, 0.06)"
        >
          过流{{ overCurrentTimes }}次</PlainTag
        >
        <PlainTag
          v-if="unTrustTimes > 0"
          color="rgba(247, 167, 13, 1)"
          bgColor="rgba(247, 167, 13, 0.06)"
        >
          不可信{{ unTrustTimes }}次</PlainTag
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import PlainTag from '@/components/PlainTag/index.vue';

let props = defineProps({
  label: {
    type: String,
    default: '',
  },
  bgIcon: {
    type: String,
    default: '',
  },
  // 状态
  status: {
    type: [String, Number],
    default: '',
  },
  // 过高次数
  overTimes: {
    type: [String, Number],
    default: '',
  },
  // 过低次数
  underTimes: {
    type: [String, Number],
    default: '',
  },
  // 不可信次数
  unTrustTimes: {
    type: [String, Number],
    default: '',
  },
  // 过流次数
  overCurrentTimes: {
    type: [String, Number],
    default: '',
  },
  color: {
    type: String,
    default: '',
  },
  showTag: {
    type: Boolean,
    default: true,
  },
});
</script>

<style lang="scss" scoped>
.car-info-card {
  position: relative;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
  width: 100%;
  height: 100%;
  min-height: 103px;
  border-radius: 4px;
  border: 1px solid var(--PC--, #e5e6eb);
  background: var(--PC-, #fff);
  .corner-mark {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .car-info-card-label {
    color: var(--PC-, #4e5969);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .car-info-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
  }
  .car-info-card-status {
    color: var(--PC-, #1d2129);
    font-family: 'PingFang SC';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 24px;
    line-height: 24px;
    z-index: 1;
  }
}
</style>
