<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-button
          v-if="ruleData.ruleType == '10002'"
          @click="addHandler"
          type="primary"
          style=""
          >新增</el-button
        >
      </div>
    </div>
    <div class="content-box">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column
          prop="ruleName"
          label="所属规则"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="changeTypeName"
          label="计费类型"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="typeName"
          label="数据类型"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="hours"
          align="center"
          label="每小时/每度-单价"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="时长"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="费用"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="editCardHandler(scope.row)"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <!-- <el-link @click="getUseRecodsHandler(scope.row)">使用记录</el-link> -->
            <el-button
               
              type="danger"
              v-if="
                ruleData.ruleType == '10002' &&
                  !(scope.row.chargeType == '3002' && scope.row.type == '1') &&
                  !(scope.row.chargeType == '3001' && scope.row.type == '2')
              "
              @click="deleteHandler(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="150px"
        :rules="formRule"
      >
        <el-form-item
          v-if="hiddenClassForDegree"
          :class="[formItem]"
          label="计费类型"
          prop="chargeType"
        >
          <el-select
            style="width:300px"
            disabled
            v-model="formData.chargeType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in chargeTypeList"
              :key="item.code"
              :value="item.code"
              :label="item.codeName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="hiddenClassForDegree"
          :class="[formItem]"
          label="类型"
          prop="type"
        >
          <el-select
            disabled
            style="width:300px"
            v-model="formData.type"
            placeholder="请选择"
          >
            <el-option label="固定" value="1"></el-option>
            <el-option label="自定义时长" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="hiddenClassForDegree && exceedClass"
          :class="[formItem]"
          label="规则大于某值"
          prop="granternum"
        >
          <el-input
            type="number"
            v-model="formData.granternum"
            style="width:300px;"
            placeholder="规则大于某值"
          />
        </el-form-item>
        <el-form-item
          v-if="hiddenClassForDegree && exceedClass"
          :class="[formItem]"
          label="规则大于某值时价格"
          prop="granterprice"
        >
          <el-input
            type="number"
            maxlength="5"
            v-model="formData.granterprice"
            style="width:300px;"
            placeholder="规则大于某值时价格"
          />
        </el-form-item>
        <el-form-item
          id="price_per"
          :class="[formItem]"
          label="每小时(或度)单价"
          prop="hours"
        >
          <el-input
            min="0"
            maxlength="6"
            v-model="formData.hours"
            style="width:300px;"
            placeholder="每小时单价"
          />
        </el-form-item>

        <el-form-item
          v-if="hiddenClassForDegree"
          :class="[formItem]"
          label="时长"
          prop="time"
        >
          <el-input
            maxlength="6"
            v-model="formData.time"
            style="width:300px;"
            placeholder="时长"
          />
        </el-form-item>

        <el-form-item
          v-if="hiddenClassForDegree"
          :class="[formItem]"
          label="费用"
          prop="price"
        >
          <el-input
            maxlength="10"
            v-model="formData.price"
            style="width:300px;"
            placeholder="费用"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button :disabled="isDisable" type="primary" @click="confimHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRulePriceList,
  editCustomPrice,
  addCustomPrice,
  delCustomPrice,
  getRule,
} from "@/api/rule";
import { getCodesByParent } from "@/api/common";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      ruleData: {},
      chargeTypeList: [],
      isDisable: false,
      dataStatus: null,
      showStatus: null,
      dialogVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      popupModalTitle: "新增",
      loading: true,
      formData: {
        chargeType: "",
        granternum: "",
        granterprice: "",
        hours: "",
        id: 0,
        price: "",
        ruleId: "",
        time: "",
        type: "",
      },
      options: [],
      formRule: {
        chargeType: [
          { required: true, message: "请选择计费类型", trigger: "blur" },
        ],
        hours: [
          { required: true, message: "请输入每小时单价", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        time: [{ required: true, message: "请输入时长", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        granternum: [
          { required: true, message: "请输入规则大于某值", trigger: "blur" },
        ],
        granterprice: [
          {
            required: true,
            message: "请输入规则大于某值时价格",
            trigger: "blur",
          },
        ],
      },
      priceId: "",
      hiddenClassForDegree: true,
      exceedClass: true,
      formItem: "nav-form-item",
    };
  },
  created() {
    this.getDataList();
    this.getCodesByParent();
    this.getRule();
  },
  watch: {
    "formData.hours": {
      handler: function(v) {
        if (v && this.formData.time) {
          this.formData.price = v * this.formData.time;
        }
      },
    },
    "formData.time": {
      handler: function(v) {
        if (v && this.formData.hours) {
          this.formData.price = v * this.formData.hours;
        }
      },
    },
  },
  methods: {
    getCodesByParent() {
      let param = {
        parentCode: 3,
      };
      getCodesByParent(param).then((res) => {
        this.chargeTypeList = [...res.data];
      });
    },
    getRule() {
      let param = {
        id: this.$route.query.id,
      };
      getRule(param).then((res) => {
        console.log(res.data);
        this.ruleData = res.data;
      });
    },
    getDataList() {
      this.loading = true;
      let param = {
        id: this.$route.query.id,
      };
      getRulePriceList(param).then((res) => {
        if (res.data && res.data.length > 0) {
          this.tableData = [...res.data];
        }

        this.loading = false;
      });
    },
    addHandler() {
      this.hiddenClassForDegree = true;
      this.isAdd = true;
      this.formData.chargeType = "3001";
      this.formData.granternum = "";
      this.formData.granterprice = "";
      this.formData.hours = "";
      this.formData.id = 0;
      this.formData.price = "";
      this.formData.ruleId = this.$route.query.id;
      this.formData.time = "";
      this.formData.type = "1";
      this.popupModalTitle = "新增";
      this.exceedClass = false;
      this.dialogVisible = true;
    },
    confimHandler() {
      if (this.formData.hours && this.formData.hours <= 0) {
        this.$message.error("每小时单价应大于0");
        return false;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addCard();
          } else {
            this.editCard();
          }
        }
      });
    },
    deleteHandler(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delCustomPrice(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.message.message);
          }
        });
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.hiddenClassForDegree = true;
      this.exceedClass = true;
      this.priceId = row.id;
      let param = {
        id: row.id,
      };
      this.formData.chargeType = row.chargeType;
      this.formData.granternum = row.granternum;
      this.formData.granterprice = row.granterprice;
      this.formData.hours = row.hours;
      this.formData.id = row.id;
      this.formData.price = row.price;
      this.formData.ruleId = this.$route.query.id;
      this.formData.time = row.time;
      this.formData.type = row.type;
      this.popupModalTitle = "编辑";
      if (row.chargeType == "3002" || row.ruleType == "10001") {
        this.hiddenClassForDegree = false;
      }
      if (row.chargeType == "3001" && row.type == "1") {
        this.exceedClass = false;
      }
      if (row.chargeType == "3001" && row.type == "2") {
        this.hiddenClassForDegree = false;
      }
      if (row.chargeType == "3001" && row.ruleType == "10001") {
        this.hiddenClassForDegree = false;
      }
      this.dialogVisible = true;
    },
    editCard() {
      this.isDisable = true;
      this.formData.id = this.priceId;
      editCustomPrice(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
        this.isDisable = false;
      });
    },
    addCard() {
      this.isDisable = true;
      this.formData.ruleId = this.$route.query.id;
      addCustomPrice(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.isDisable = false;
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    getUseRecodsHandler(row) {
      this.$router.push({
        path: "card/useRecods",
        query: {
          cardNo: row.cardNo,
        },
      });
    },
  },
};
</script>
<style scoped>
.hidden {
  display: none;
}
</style>
