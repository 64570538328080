import { Decimal } from 'decimal.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function getIntervalData(value = []) {
  // 最大值*2 变个位数 向上取整 /2 变原位数
  let tmpMax = Math.max(...value);
  let tmpMin = Math.min(...value, 0);
  let tmpMaxLen = 10 ** (parseInt(tmpMax).toString().length - 1);
  let tmpMinLen = 10 ** (parseInt(tmpMin).toString().length - 1);
  let max = new Decimal(
    new Decimal(
      Math.ceil(
        new Decimal(Math.ceil(tmpMax)).mul(new Decimal(2).div(tmpMaxLen))
      )
    )
      .div(new Decimal(2))
      .mul(tmpMaxLen)
  ).toNumber();
  let min = new Decimal(
    new Decimal(
      Math.floor(
        new Decimal(Math.floor(tmpMin)).mul(new Decimal(2).div(tmpMinLen))
      )
    )
      .div(new Decimal(2))
      .mul(tmpMinLen)
  ).toNumber();
  let interval = new Decimal(max)
    .sub(new Decimal(min))
    .div(new Decimal(5), 2)
    .toNumber();
  return {
    max,
    min,
    interval,
  };
}
let waveIconSvgPath =
  'path://M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z';
export function useChartOption() {
  // 充电趋势
  let getChartSOCOption = (data, param = {}) => {
    return {
      color: ['#18ADD0', '#F7A70D'],
      title: {
        text: '单位 (度)',
        left: '11',
        top: '-2',
        textStyle: {
          fontSize: 12,
          fontWeight: 400,
          color: '#86909C',
        },
      },
      legend: {
        bottom: 10,
        icon: 'circle',
        itemWidth: 10,
        textStyle: {
          color: 'rgba(78, 89, 105, 1)',
        },
        icon: waveIconSvgPath,
      },
      dataset: {
        source: data,
      },
      tooltip: {
        trigger: 'axis',
        icon: waveIconSvgPath,
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p style="color: var(--Text-5-, var(--PC-, #1D2129));font-size: 12px;">${
                item.axisValue
              }</p>`;
            }
            htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> 
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
              item.value[item.dimensionNames[item.encode.y[0]]]
            }${index === 0 ? '度' : '%'}</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 30,
        right: 16,
        left: 16,
        bottom: 40,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisLine: {
          onZero: true,
          lineStyle: {
            color: '#E5E6EB',
          },
        },
        axisLabel: {
          color: '#86909C',
          showMinLabel: true,
          showMaxLabel: true,
          alignMaxLabel: data.length > 5 ? 'right' : 'center',
          formatter(value) {
            return dayjs(value).format('HH:mm:ss');
          },
        },
        axisTick: {
          show: false,
        },
      },
      axisPointer: {
        lineStyle: {
          color: '#C9CDD4',
          type: 'solid',
        },
      },
      yAxis: [
        {
          // name: "单位 (度)",
          type: 'value',
          ...getIntervalData(
            data.map((item) => {
              return item.chargePower;
            })
          ),
          nameTextStyle: {
            color: '#86909C',
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#86909C',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#E5E6EB'],
              opacity: 0.5,
            },
          },
        },
        {
          ...(param.isDc
            ? {
                name: '百分比',
                type: 'value',
                ...getIntervalData(
                  data.map((item) => {
                    return item.soc;
                  })
                ),
                nameTextStyle: {
                  color: '#86909C',
                },
                axisLine: {
                  show: false,
                },
                axisLabel: {
                  color: '#86909C',
                  formatter(value) {
                    return `${value}%`;
                  },
                },
                splitLine: {
                  lineStyle: {
                    type: 'dashed',
                    color: ['#75E6F3'],
                    opacity: 0.1,
                  },
                },
              }
            : {}),
        },
      ],
      series: [
        {
          name: '充电量',
          type: 'line',
          yAxisIndex: 0,
          zlevel: 20,
          smooth: true,
          lineStyle: {
            color: '#18ADD0',
          },
          symbolSize: 8,
          encode: {
            x: 'createTime',
            y: 'chargePower',
          },
          showSymbol: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(24, 173, 208, 0.12)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
        param.isDc
          ? {
              name: 'SOC',
              type: 'line',
              yAxisIndex: 1,
              zlevel: 20,
              smooth: true,
              showSymbol: false,
              symbolSize: 8,
              lineStyle: {
                color: '#F7A70D',
              },
              encode: {
                x: 'createTime',
                y: 'soc',
              },
              label: {
                color: '#16c5ee',
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(255, 76, 19, 0.12)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                    },
                  ],
                },
              },
            }
          : null,
      ],
    };
  };
  let getPowerLineOption = (data) => {
    return {
      color: ['#18ADD0', '#F7A70D'],
      title: {
        text: '单位 (kW)',
        left: '11',
        top: '-2',
        textStyle: {
          fontSize: 12,
          fontWeight: 400,
          color: '#86909C',
        },
      },
      legend: {
        bottom: 10,
        icon: 'circle',
        itemWidth: 10,
        textStyle: {
          color: 'rgba(78, 89, 105, 1)',
        },
        icon: waveIconSvgPath,
      },
      dataset: {
        source: data,
      },
      tooltip: {
        trigger: 'axis',
        icon: waveIconSvgPath,
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                item.axisValue
              }</p>`;
            }
            htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> 
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
              item.value[item.dimensionNames[item.encode.y[0]]]
            }kW</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 30,
        right: 16,
        left: 16,
        bottom: 40,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisLine: {
          onZero: true,
          lineStyle: {
            color: '#E5E6EB',
          },
        },
        axisLabel: {
          color: '#86909C',
          showMinLabel: true,
          showMaxLabel: true,
          alignMaxLabel: data.length > 5 ? 'right' : 'center',
          formatter(value) {
            return dayjs(value).format('HH:mm:ss');
          },
        },
        axisTick: {
          show: false,
        },
      },
      axisPointer: {
        lineStyle: {
          color: '#C9CDD4',
          type: 'solid',
        },
      },
      yAxis: {
        // name: "单位 (kW)",
        type: 'value',
        ...getIntervalData(
          data.map((item) => {
            return item.power;
          })
        ),
        nameTextStyle: {
          color: '#86909C',
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#86909C',
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: ['#E5E6EB'],
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: '功率',
          type: 'line',
          zlevel: 20,
          showSymbol: false,
          symbolSize: 8,
          smooth: true,
          encode: {
            x: 'createTime',
            y: 'power',
          },
          tooltip: {
            valueFormatter: (value) => value + ' 度',
          },
          label: {
            color: '#18ADD0',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(24, 173, 208, 0.12)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  let getCurrentLineOption = (data, param = {}) => {
    if (param.isDc) {
      return {
        color: ['#18B9DF', '#2CE0DD', '#F7A70D'],
        title: {
          text: '单位 (A)',
          left: '11',
          top: '-2',
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#86909C',
          },
        },
        legend: {
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
          textStyle: {
            color: 'rgba(78, 89, 105, 1)',
          },
          icon: waveIconSvgPath,
        },
        dataset: {
          source: data,
        },
        tooltip: {
          trigger: 'axis',
          icon: waveIconSvgPath,
          formatter(params) {
            let htmlText = '';
            params.forEach((item, index) => {
              if (index === 0) {
                htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                  item.axisValue
                }</p>`;
              }
              htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> 
              <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
              <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
                item.color
              }"/></svg>
               ${
                 item.seriesName
               }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
                item.value[item.dimensionNames[item.encode.y[0]]]
              }A</span></p>`;
            });
            return htmlText;
          },
        },
        grid: {
          top: 30,
          right: 16,
          left: 46,
          bottom: 60,
        },
        xAxis: {
          type: 'category',
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#E5E6EB',
            },
          },
          axisLabel: {
            color: '#86909C',
            hideOverlap: true,
            showMinLabel: true,
            showMaxLabel: true,
            alignMaxLabel: data.length > 5 ? 'right' : 'center',
            formatter(value) {
              return dayjs(value).format('HH:mm:ss');
            },
          },
          axisTick: {
            show: false,
          },
        },
        axisPointer: {
          lineStyle: {
            color: '#C9CDD4',
            type: 'solid',
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: '#86909C',
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#86909C',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#E5E6EB'],
              opacity: 0.5,
            },
          },
          // ...getIntervalData(
          //   data.map((item) => {
          //     return item.electricityStr;
          //   })
          // ),
        },
        series: [
          {
            name: 'BMS电流测量值',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'bmsMat',
            },
            label: {
              color: '#18B9DF',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(24, 185, 223, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            name: 'BMS需求电流',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'currentReq',
            },
            label: {
              color: '#2CE0DD',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(44, 224, 221, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            name: '实际输出电流',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'pileOutCurrent',
            },
            label: {
              color: '#F7A70D',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 76, 19, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ].filter((item) => {
          return param.isDc ? true : ['实际输出电流'].includes(item.name);
        }),
      };
    } else {
      return {
        color: ['#18B9DF', '#2CE0DD', '#F7A70D'],
        title: {
          text: '单位 (A)',
          left: '11',
          top: '-2',
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#86909C',
          },
        },
        legend: {
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
          textStyle: {
            color: 'rgba(78, 89, 105, 1)',
          },
          icon: waveIconSvgPath,
        },
        dataset: {
          source: data,
        },
        tooltip: {
          trigger: 'axis',
          icon: waveIconSvgPath,
          formatter(params) {
            let htmlText = '';
            params.forEach((item, index) => {
              if (index === 0) {
                htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                  item.axisValue
                }</p>`;
              }
              htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> 
              <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
              <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
                item.color
              }"/></svg>
               ${
                 item.seriesName
               }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
                item.value[item.dimensionNames[item.encode.y[0]]]
              }A</span></p>`;
            });
            return htmlText;
          },
        },
        grid: {
          top: 30,
          right: 16,
          left: 46,
          bottom: 60,
        },
        xAxis: {
          type: 'category',
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#E5E6EB',
            },
          },
          axisLabel: {
            color: '#86909C',
            hideOverlap: true,
            showMinLabel: true,
            showMaxLabel: true,
            alignMaxLabel: data.length > 5 ? 'right' : 'center',
            formatter(value) {
              return dayjs(value).format('HH:mm:ss');
            },
          },
          axisTick: {
            show: false,
          },
        },
        axisPointer: {
          lineStyle: {
            color: '#C9CDD4',
            type: 'solid',
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: '#86909C',
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#86909C',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#E5E6EB'],
              opacity: 0.5,
            },
          },
          // ...getIntervalData(
          //   data.map((item) => {
          //     return item.electricityStr;
          //   })
          // ),
        },
        series: [
          {
            name: '实际输出电流',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'electricity',
            },
            label: {
              color: '#F7A70D',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 76, 19, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ].filter((item) => {
          return param.isDc ? true : ['实际输出电流'].includes(item.name);
        }),
      };
    }
  };
  let getVoltageLineOption = (data, param = {}) => {
    if (param.isDc) {
      return {
        color: ['#18B9DF', '#2CE0DD', '#F7A70D'],
        title: {
          text: '单位 (V)',
          left: '11',
          top: '-2',
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#86909C',
          },
        },
        legend: {
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
          textStyle: {
            color: 'rgba(78, 89, 105, 1)',
          },
          icon: waveIconSvgPath,
        },
        dataset: {
          source: data,
        },
        tooltip: {
          trigger: 'axis',
          icon: waveIconSvgPath,
          formatter(params) {
            let htmlText = '';
            params.forEach((item, index) => {
              if (index === 0) {
                htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                  item.axisValue
                }</p>`;
              }
              htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> 
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
                item.value[item.dimensionNames[item.encode.y[0]]]
              }V</span></p>`;
            });
            return htmlText;
          },
        },
        grid: {
          top: 30,
          right: 16,
          left: 46,
          bottom: 60,
        },
        xAxis: {
          type: 'category',
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#E5E6EB',
            },
          },
          axisLabel: {
            color: '#86909C',
            hideOverlap: true,
            showMinLabel: true,
            showMaxLabel: true,
            alignMaxLabel: data.length > 5 ? 'right' : 'center',
            formatter(value) {
              return dayjs(value).format('HH:mm:ss');
            },
          },
          axisTick: {
            show: false,
          },
        },
        axisPointer: {
          lineStyle: {
            color: '#C9CDD4',
            type: 'solid',
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: '#86909C',
            width: 100,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#86909C',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#E5E6EB'],
              opacity: 0.5,
            },
          },
          // ...getIntervalData(
          //   data.map((item) => {
          //     return item.electricityStr;
          //   })
          // ),
        },
        series: [
          {
            name: 'BMS电压测量值',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'bmsVcc',
            },
            label: {
              color: '#18B9DF',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(24, 185, 223, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            name: 'BMS需求电压',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'voltageReq',
            },
            label: {
              color: '#2CE0DD',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(44, 224, 221, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            name: '实际输出电压',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'pileOutVoltage',
            },
            label: {
              color: '#F7A70D',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 76, 19, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ].filter((item) => {
          return param.isDc ? true : ['实际输出电压'].includes(item.name);
        }),
      };
    } else {
      return {
        color: ['#18B9DF', '#2CE0DD', '#F7A70D'],
        title: {
          text: '单位 (V)',
          left: '11',
          top: '-2',
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#86909C',
          },
        },
        legend: {
          bottom: 10,
          icon: 'circle',
          itemWidth: 10,
          textStyle: {
            color: 'rgba(78, 89, 105, 1)',
          },
          icon: waveIconSvgPath,
        },
        dataset: {
          source: data,
        },
        tooltip: {
          trigger: 'axis',
          icon: waveIconSvgPath,
          formatter(params) {
            let htmlText = '';
            params.forEach((item, index) => {
              if (index === 0) {
                htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                  item.axisValue
                }</p>`;
              }
              htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> 
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
                item.value[item.dimensionNames[item.encode.y[0]]]
              }V</span></p>`;
            });
            return htmlText;
          },
        },
        grid: {
          top: 30,
          right: 16,
          left: 46,
          bottom: 60,
        },
        xAxis: {
          type: 'category',
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#E5E6EB',
            },
          },
          axisLabel: {
            color: '#86909C',
            hideOverlap: true,
            showMinLabel: true,
            showMaxLabel: true,
            alignMaxLabel: data.length > 5 ? 'right' : 'center',
            formatter(value) {
              return dayjs(value).format('HH:mm:ss');
            },
          },
          axisTick: {
            show: false,
          },
        },
        axisPointer: {
          lineStyle: {
            color: '#C9CDD4',
            type: 'solid',
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: '#86909C',
            width: 100,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#86909C',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#E5E6EB'],
              opacity: 0.5,
            },
          },
        },
        series: [
          {
            name: '实际输出电压',
            type: 'line',
            zlevel: 20,
            showSymbol: false,
            symbolSize: 8,
            smooth: true,
            encode: {
              x: 'createTime',
              y: 'voltage',
            },
            label: {
              color: '#F7A70D',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 76, 19, 0.12)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      };
    }
  };
  let getConnectorLineTempOption = (data) => {
    return {
      color: ['#3BCBF3'],
      title: {
        text: '单位 (℃)',
        left: '11',
        top: '-2',
        textStyle: {
          fontSize: 12,
          fontWeight: 400,
          color: '#86909C',
        },
      },
      legend: {
        bottom: 10,
        icon: 'circle',
        itemWidth: 10,
        textStyle: {
          color: 'rgba(78, 89, 105, 1)',
        },
        icon: waveIconSvgPath,
      },
      dataset: {
        source: data,
      },
      tooltip: {
        trigger: 'axis',
        icon: waveIconSvgPath,
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                item.axisValue
              }</p>`;
            }
            htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between">
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
              item.value[item.dimensionNames[item.encode.y[0]]]
            }℃</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 30,
        right: 16,
        left: 46,
        bottom: 60,
      },
      xAxis: {
        type: 'category',
        axisLine: {
          onZero: true,
          lineStyle: {
            color: '#E5E6EB',
          },
        },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          alignMaxLabel: data.length > 5 ? 'right' : 'center',
          color: '#86909C',
          formatter(value) {
            return dayjs(value).format('HH:mm:ss');
          },
        },
        interval: 0, // 0 表示强制显示所有标签，'auto' 表示自动间隔

        axisTick: {
          show: false,
        },
      },
      axisPointer: {
        lineStyle: {
          color: '#C9CDD4',
          type: 'solid',
        },
      },
      yAxis: {
        type: 'value',
        nameTextStyle: {
          color: '#86909C',
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#86909C',
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: ['#E5E6EB'],
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: '枪线温度',
          type: 'line',
          zlevel: 20,
          showSymbol: false,
          symbolSize: 8,
          smooth: true,
          encode: {
            x: 'createTime',
            y: 'connectorLineTemp',
          },
          label: {
            color: '#3BCBF3',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(59, 203, 243, 0.12)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  let getBpMaxTempOption = (data) => {
    return {
      color: ['#3BCBF3'],
      title: {
        text: '单位 (℃)',
        left: '11',
        top: '-2',
        textStyle: {
          fontSize: 12,
          fontWeight: 400,
          color: '#86909C',
        },
      },
      legend: {
        bottom: 10,
        icon: 'circle',
        itemWidth: 10,
        textStyle: {
          color: 'rgba(78, 89, 105, 1)',
        },
        icon: waveIconSvgPath,
      },
      dataset: {
        source: data,
      },
      tooltip: {
        trigger: 'axis',
        icon: waveIconSvgPath,
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p style="color: #1D2129;font-size: 12px;">${
                item.axisValue
              }</p>`;
            }
            htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between">
            <span style="display: inline-block;min-width: 50px;font-size: 12px;color: var(--PC-, #86909C);">
            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="&#232;&#183;&#175;&#229;&#190;&#132; (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M3.81641 1.5C2.61383 1.5 1.56641 2.57745 1.56641 4C1.56641 4.27614 1.34255 4.5 1.06641 4.5C0.790264 4.5 0.566406 4.27614 0.566406 4C0.566406 2.10885 1.98142 0.5 3.81641 0.5C5.65139 0.5 7.06641 2.10885 7.06641 4C7.06641 5.42255 8.11383 6.5 9.31641 6.5C10.519 6.5 11.5664 5.42255 11.5664 4C11.5664 3.72386 11.7903 3.5 12.0664 3.5C12.3425 3.5 12.5664 3.72386 12.5664 4C12.5664 5.89115 11.1514 7.5 9.31641 7.5C7.48142 7.5 6.06641 5.89115 6.06641 4C6.06641 2.57745 5.01898 1.5 3.81641 1.5Z" fill="${
              item.color
            }"/></svg>
             ${
               item.seriesName
             }</span> <span style="display: inline-block; margin-left: 10px;min-width: 40px;text-align:right;color: var(--PC-, #4E5969);font-size: 13px;">${
              item.value[item.dimensionNames[item.encode.y[0]]]
            }℃</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 30,
        right: 16,
        left: 46,
        bottom: 60,
      },
      xAxis: {
        type: 'category',
        axisLine: {
          onZero: true,
          lineStyle: {
            color: '#E5E6EB',
          },
        },
        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          alignMaxLabel: data.length > 5 ? 'right' : 'center',
          color: '#86909C',
          formatter(value) {
            return dayjs(value).format('HH:mm:ss');
          },
        },
        interval: 0, // 0 表示强制显示所有标签，'auto' 表示自动间隔

        axisTick: {
          show: false,
        },
      },
      axisPointer: {
        lineStyle: {
          color: '#C9CDD4',
          type: 'solid',
        },
      },
      yAxis: {
        type: 'value',
        nameTextStyle: {
          color: '#86909C',
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#86909C',
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: ['#E5E6EB'],
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: '电池组内最高温度',
          type: 'line',
          zlevel: 20,
          showSymbol: false,
          symbolSize: 8,
          smooth: true,
          encode: {
            x: 'createTime',
            y: 'bpMaxTemp',
          },
          label: {
            color: '#3BCBF3',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(59, 203, 243, 0.12)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  return {
    getChartSOCOption,
    getPowerLineOption,
    getCurrentLineOption,
    getVoltageLineOption,
    getConnectorLineTempOption,
    getBpMaxTempOption,
  };
}
