var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "box-container", class: { activated: _vm.activated } },
    [
      _c("div", { staticClass: "box-header" }, [
        _c(
          "div",
          {
            staticClass: "status-desc",
            style: { color: _setup.chargeColorDic[_vm.data.stat] },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_setup.connectorStatFormat(_vm.data.stat)) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        [1, 3].includes(_vm.data.stat)
          ? _c(
              "div",
              {
                staticClass: "btn-detail",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _setup.handleDetail.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v("\n      详情 "),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "time-line-container" }, [
        _c("div", { staticClass: "time-line-item" }, [
          _c("img", {
            staticClass: "time-line-icon",
            attrs: { src: _setup.endIcon },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "time-line-text" }, [
            _vm._v(_vm._s(_vm.data.stopTime || "--")),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "time-line-item" },
          [
            _c("svg-icon", {
              staticStyle: { "font-size": "8px", "margin-left": "5px" },
              attrs: { "icon-class": "line-52" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "time-line-item" }, [
          _c("img", {
            staticClass: "time-line-icon",
            attrs: { src: _setup.startIcon },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "time-line-text" }, [
            _vm._v(_vm._s(_vm.data.startTime || "--")),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.activated
        ? _c("img", {
            staticClass: "popup-arrow",
            attrs: { src: require("@/assets/popup-arrow.png"), alt: "" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }