<template>
  <div class="main">
    <div class="main-scroll-container">
      <div class="main-info-box">
        <div
            class="main-info-box-left"
            :style="{
            background: connectorStatFormat.bgColor
          }"
        >
          <div class="progress-container">
            <el-progress
                type="circle"
                :percentage="
                headerData.connectorStat === 3 ? headerData.soc || 0 : 0
              "
                :width="50"
                :stroke-width="4"
                :define-back-color="connectorStatFormat.progressColor"
                :color="connectorStatFormat.color"
                :text-color="connectorStatFormat.color"
                :format="
                () => {
                  return connectorStatFormat.label;
                }
              "
            ></el-progress>
            <svg-icon
                v-if="headerData.connectorStat === 3"
                icon-class="flash"
                class="right-bottom-icon"
            />
          </div>
          <div style="display:flex; gap:4px;">
            <div class="progress-text" v-if="headerData.connectorStat === 3">
              {{
                headerData.pileType === 0
                    ? (headerData.hasChargePower || "-") + "度"
                    : (headerData.soc || "-") + "%"
              }}
            </div>
            <PlainTag
                :color="connectorStatFormat.color"
                :bgColor="connectorStatFormat.textBgColor"
            >
              {{ miniTagFormatter(headerData) }}
            </PlainTag>
          </div>
        </div>
        <div class="main-info-box-right">
          <div class="text-title">
            {{ headerData.plotName || "-" }} /
            {{ headerData.pileName || "-" }} /
            {{ headerData.connectorName || "-" }}
            {{
              headerData.connectorCode ? `(${headerData.connectorCode})` : ""
            }}
            <el-popover
                ref="stationPopoverRef"
                placement="bottom"
                trigger="click"
            >
              <div>
                <el-cascader-panel
                    :options="stationTree"
                    :border="false"
                    :props="{
                    value: 'code',
                    label: 'name'
                  }"
                    @change="handleGunChange"
                ></el-cascader-panel>
              </div>
              <svg-icon
                  icon-class="switch"
                  class="station-switch"
                  slot="reference"
              ></svg-icon>
            </el-popover>
          </div>
          <el-row type="flex" class="main-info-descriptions">
            <el-col
                :span="8"
                v-for="(item, index) in pileInfoItemList"
                :key="index"
                class="text-descriptions"
            >
              <div class="text-descriptions-label">
                {{ item.label }}
              </div>
              <div
                  class="text-descriptions-content"
                  :style="{ color: headerData[item.field] ? item.color : '' }"
              >
                {{
                  ((item.formatter
                          ? item.formatter(headerData[item.field])
                          : headerData[item.field]) ||
                      item.default ||
                      "-") + item.unit
                }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="main-content-container">
        <div class="main-content-container-left">
          <el-date-picker
              v-model="filterInput"
              type="date"
              style="margin-right:16px"
              placeholder="选择日期"
              :clearable="false"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="handleDateFilterChange"
              :picker-options="dateFilterOptions"
          >
          </el-date-picker>
          <div class="timeline-container">
            <div style="flex:1;" v-if="activities.length > 0">
              <el-timeline
                  style="padding-top: 10px;padding-right: 8px;position:absolute"
              >
                <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :color="
                    activatedIndex === index ? 'rgba(5, 124, 152, 0.08)' : ''
                  "
                    :size="activatedIndex === index ? 'large' : ''"
                >
                  <chargingTimelineBlock
                      style="cursor: pointer;"
                      :activated="
                      activatedIndex ===
                        activity.startTime + activity.stopTime + activity.stat
                    "
                      :data="activity"
                      @click.native="handleTimelineClick(activity)"
                      @detail="handleDetailClick(activity)"
                  ></chargingTimelineBlock>
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="el-tree__empty-block" v-else>
              <span class="el-tree__empty-text">暂无数据</span>
            </div>
          </div>
        </div>
        <div class="main-content-container-right">
          <el-tabs v-model="currentTab" @tab-click="handleClick">
            <el-tab-pane
                class="right-panel-tab-container"
                label="充电信息"
                name="first"
            >
              <div
                  v-if="activatedRow?.stat === 3"
                  class="tab-content-container"
              >
                <div class="box-container">
                  <div class="box-content box-content-left">
                    <div class="box-mini-title">充电信息</div>
                    <div
                        class="info-card-row"
                        style="grid-template-columns: 230px 1fr"
                    >
                      <div
                          class="info-row-item"
                          v-for="(item, index) in chargeInfoFormList"
                          :key="index"
                          v-if="!item.hidden"
                      >
                        <div class="info-row-label">{{ item.label }}</div>
                        <div class="info-row-content" v-overflow-tooltip>
                          {{
                            ((item.formatter
                                ? item.formatter(
                                chargeInfoData[item.field],
                                chargeInfoData,
                            ) + item.unit
                                : chargeInfoData[item.field]) || "-") + item.unit
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box-content box-content-right">
                    <div class="box-mini-title">车主信息</div>
                    <div
                        class="info-card-row"
                        style="grid: auto-flow dense / 50% 50%;"
                    >
                      <div
                          class="info-row-item"
                          v-for="(item, index) in carerInfoFormList"
                          :key="index"
                          v-if="!item.hidden"
                          :style="{
                          gridColumn: [0, 1].includes(index) ? 'auto' : '1/3'
                        }"
                      >
                        <div class="info-row-label">
                          {{ item.label }}
                        </div>
                        <div
                            class="info-row-content"
                            v-overflow-tooltip
                            :style="{ color: index === 4 ? '#DF3737' : '' }"
                        >
                          {{
                            ((item.formatter
                                ? (item.formatter(
                                chargeInfoData[item.field],
                                chargeInfoData,
                            ) || "-") + item.unit
                                : chargeInfoData[item.field]) || "-") + item.unit
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chart-box-layer">
                  <div class="chart-box">
                    <div class="chart-box-title">
                      充电量
                    </div>
                    <el-empty
                        v-if="isSocChartNull"
                        style="height: 334px;width: 100%;"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"
                    ></el-empty>
                    <v-chart
                        v-else
                        ref="socChartRef"
                        style="height: 334px;"
                        :option="socChartOption"
                        autoresize
                        group="group1"
                    />
                  </div>
                  <div class="chart-box">
                    <div class="chart-box-title">
                      功率
                    </div>
                    <el-empty
                        v-if="isPowerChartNull"
                        style="height: 334px;width: 100%;"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"
                    ></el-empty>
                    <v-chart
                        v-else
                        ref="realTimeChartRef"
                        style="height: 334px;"
                        :option="powerChartOption"
                        autoresize
                        group="group1"
                    />
                  </div>
                  <div class="chart-box">
                    <div class="chart-box-title">
                      电流
                    </div>
                    <el-empty
                        v-if="isCurrentChartNull"
                        style="height: 334px;width: 100%;"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"
                    ></el-empty>
                    <v-chart
                        v-else
                        ref="realTimeChartRef"
                        style="height: 334px;"
                        :option="currentChartOption"
                        autoresize
                        group="group1"
                    />
                  </div>
                  <div class="chart-box">
                    <div class="chart-box-title">
                      电压
                    </div>
                    <el-empty
                        v-if="isVoltageChartNull"
                        style="height: 334px;width: 100%;"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"
                    ></el-empty>
                    <v-chart
                        v-else
                        ref="realTimeChartRef"
                        style="height: 334px;"
                        :option="voltageChartOption"
                        autoresize
                        group="group1"
                    />
                  </div>
                  <div class="chart-box">
                    <div class="chart-box-title">
                      枪线温度
                    </div>
                    <el-empty
                        v-if="isConnectorLineTempChartNull"
                        style="height: 334px;width: 100%;"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"
                    ></el-empty>
                    <v-chart
                        v-else
                        ref="realTimeChartRef"
                        style="height: 334px;"
                        :option="connectorLineTempChartOption"
                        autoresize
                        group="group1"
                    />
                  </div>
                  <div class="chart-box" v-if="isDc">
                    <div class="chart-box-title">
                      电池组最高温度
                    </div>
                    <el-empty
                        v-if="isBpMaxTempChartNull"
                        style="height: 334px;width: 100%;"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"
                    ></el-empty>
                    <v-chart
                        v-else
                        ref="realTimeChartRef"
                        style="height: 334px;"
                        :option="bpMaxTempChartOption"
                        autoresize
                        group="group1"
                    />
                  </div>
                </div>
              </div>
              <el-empty
                  v-else
                  class="empty-block"
                  image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                  :image-size="48"
              ></el-empty>
            </el-tab-pane>
            <el-tab-pane
                label="电池信息"
                name="second"
                v-if="isDc"
                class="right-panel-tab-container"
            >
              <div
                  class="tab-content-container"
                  v-if="activatedRow?.stat === 3"
              >
                <div class="box-container">
                  <div class="box-content">
                    <div class="box-mini-title">
                      {{ batteryTypeFormat(batteryInfo.batType) || "-" }}
                      <PlainTag v-if="batteryInfo.batProdName">
                        {{ batteryInfo.batProdName }}
                      </PlainTag>
                    </div>
                    <div class="info-card-row">
                      <div
                          class="info-row-item"
                          v-for="(item, index) in batteryInfoFormList"
                          :key="index"
                      >
                        <div class="info-row-label">{{ item.label }}</div>
                        <div
                            class="info-row-content ellipsis"
                            v-overflow-tooltip
                        >
                          {{ item.prefix }}
                          {{
                            (item.formatter
                                ? item.formatter(
                                    batteryInfo[item.field],
                                    batteryInfo,
                                )
                                : batteryInfo[item.field]) || "-"
                          }}
                          {{ item.unit }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="car-info-panel">
                  <div class="info-car-card">
                    <div class="info-car-image">
                      <el-image
                          :src="require('@/assets/export-car.png')"
                          :fit="'contain'"
                      ></el-image>
                      <div
                          class="info-car-card-bubble-layout info-car-card-bubble-lt"
                      >
                        <el-image
                            :src="require('@/assets/car-popper-rectangle.png')"
                            class="info-car-card-bubble-lt-arrow"
                        ></el-image>
                        <div class="info-car-card-bubble-value">
                          {{ batteryInfo.maxBatteryTemp || "-" }}°C
                        </div>
                        <div class="info-car-card-bubble-label">
                          BMS 最高电池温度
                        </div>
                        <PlainTag
                            v-for="(item, index) in batteryInfo.maxTdpNums || []"
                            :key="index"
                            type="mini"
                        >
                          {{ `编号${item}` }}
                        </PlainTag>
                      </div>
                      <div
                          class="info-car-card-bubble-layout info-car-card-bubble-rt"
                      >
                        <el-image
                            :src="require('@/assets/car-popper-rectangle.png')"
                            class="info-car-card-bubble-rt-arrow"
                        ></el-image>
                        <div class="info-car-card-bubble-value">
                          {{ batteryInfo.maxCellVoltage || "-" }}V
                        </div>
                        <div class="info-car-card-bubble-label">
                          BMS 最高单体电池电压
                        </div>
                        <PlainTag
                            v-for="(item,
                          index) in batteryInfo.maxCellVoltageNums || []"
                            :key="index"
                            type="mini"
                        >
                          {{ `编号${item}` }}
                        </PlainTag>
                      </div>
                      <div
                          class="info-car-card-bubble-layout info-car-card-bubble-lb"
                      >
                        <el-image
                            :src="require('@/assets/car-popper-rectangle.png')"
                            class="info-car-card-bubble-lb-arrow"
                        ></el-image>
                        <div class="info-car-card-bubble-value">
                          {{ batteryInfo.minBatteryTemp || "-" }}°C
                        </div>
                        <div class="info-car-card-bubble-label">
                          BMS 最低电池温度
                        </div>
                        <PlainTag
                            v-for="(item, index) in batteryInfo.minTdpNums || []"
                            :key="index"
                            type="mini"
                        >
                          {{ `编号${item}` }}
                        </PlainTag>
                      </div>
                      <div
                          class="info-car-card-bubble-layout info-car-card-bubble-rb"
                      >
                        <el-image
                            :src="require('@/assets/car-popper-rectangle.png')"
                            class="info-car-card-bubble-rb-arrow"
                        ></el-image>
                        <div class="info-car-card-bubble-value">
                          {{ batteryInfo.minCellVoltage || "-" }}V
                        </div>
                        <div class="info-car-card-bubble-label">
                          BMS 最低单体电池电压
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-card-box">
                    <el-row
                        :gutter="10"
                        type="flex"
                        style="flex-wrap: wrap;align-items: stretch;width: 100%;min-width:0;"
                    >
                      <el-col :span="12" class="info-row-item">
                        <carInfoCard
                            label="BMS电池荷电状态"
                            :bgIcon="require('@/assets/battery.png')"
                            :status="batteryVoltageFormat(batteryInfo.socStat)"
                            :color="
                            batteryVoltageColorFormat(batteryInfo.socStat)
                          "
                            :overTimes="batteryInfo.socStatHighCount"
                            :underTimes="batteryInfo.socStatLowerCount"
                        ></carInfoCard>
                      </el-col>
                      <el-col :span="12" class="info-row-item">
                        <carInfoCard
                            label="BMS电池充电电流"
                            :bgIcon="require('@/assets/carinfo_current.png')"
                            :status="chargeCurrentFormat(batteryInfo.bsmStat)"
                            :color="chargeCurrentColorFormat(batteryInfo.bsmStat)"
                            :overCurrentTimes="batteryInfo.bsmStatOverCount"
                            :unTrustTimes="batteryInfo.bsmStatUnrCount"
                        ></carInfoCard>
                      </el-col>
                      <el-col :span="12" class="info-row-item">
                        <carInfoCard
                            label="BMS电池温度"
                            :bgIcon="
                            require('@/assets/markerThermomterAngle.png')
                          "
                            :status="chargeCurrentFormat(batteryInfo.wtTempStat)"
                            :color="
                            chargeCurrentColorFormat(batteryInfo.wtTempStat)
                          "
                            :overCurrentTimes="batteryInfo.wtTempStatOverCount"
                            :unTrustTimes="batteryInfo.wtTempStatUnrCount"
                        ></carInfoCard>
                      </el-col>
                      <el-col :span="12" class="info-row-item">
                        <carInfoCard
                            label="BMS电池绝缘状态"
                            :bgIcon="require('@/assets/carinfo_insulation.png')"
                            :status="
                            chargeCurrentFormat(batteryInfo.insulateStat)
                          "
                            :color="
                            chargeCurrentColorFormat(batteryInfo.insulateStat)
                          "
                            :overCurrentTimes="batteryInfo.insulateStatOverCount"
                            :unTrustTimes="batteryInfo.insulateStatUnrCount"
                        ></carInfoCard>
                      </el-col>
                      <el-col :span="24" class="info-row-item">
                        <carInfoCard
                            label="BMS电池输出连接器状态"
                            :bgIcon="require('@/assets/carinfo_connent.png')"
                            :status="chargeCurrentFormat(batteryInfo.klrStat)"
                            :color="chargeCurrentColorFormat(batteryInfo.klrStat)"
                            :overCurrentTimes="batteryInfo.klrStatOverCount"
                            :unTrustTimes="batteryInfo.klrStatUnrCount"
                        ></carInfoCard>
                      </el-col>
                      <el-col :span="24" class="info-row-item">
                        <carInfoCard
                            label="BMS中止充电原因"
                            :bgIcon="require('@/assets/carinfo_stop.png')"
                            :status="batteryStopReason"
                            :showTag="false"
                        ></carInfoCard>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <el-empty
                  v-else
                  class="empty-block"
                  image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                  :image-size="48"
              ></el-empty>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { usePolling, useRoute, useRouter } from "@/hooks/useUtils";
import chargingTimelineBlock from "./components/chargingTimelineBlock.vue";
import VChart from "vue-echarts";
import PlainTag from "@/components/PlainTag/index.vue";
import carInfoCard from "./components/carInfoCard.vue";
import { carFilter } from "@/utils/formatter";
import {
  getPileTree,
  getStatMonitorBattery,
  getStatMonitorDetail,
  getStatMonitorList,
  getStatMonitorOrder,
} from "@/api/port";
import {
  BATTERY_GROUP_OWNER,
  BATTERY_TYPE,
  BATTERY_VOLTAGE_REALTIME,
  CHARGE_CURRENT_REALTIME,
  CHARGE_TYPE,
  CONNECTOR_RETURN,
  CONNECTOR_STAT,
  findLabelByValue,
  findValue,
} from "@/utils/const";
import dayjs from "dayjs";
import { useChartOption } from "./hooks/useChartOption";

// CHARGE_TYPE
let chargeTypeFormat = val => {
  return findLabelByValue(CHARGE_TYPE, val);
};
let pileStatusDic = {
  0: "在线",
  1: "离线",
  2: "停用",
};

function miniTagFormatter(row) {
  let group = [];

  if (chargeTypeFormat(row.pileType) !== row.pileType) {
    group.push(chargeTypeFormat(row.pileType));
  }

  if (Object.keys(pileStatusDic).includes(row.pileStat + "")) {
    group.push(pileStatusDic[row.pileStat]);
  }
  return group.join(" | ");
}

let connectorReturnFormat = val => {
  return findLabelByValue(CONNECTOR_RETURN, val);
};
let batteryTypeFormat = val => {
  return findLabelByValue(BATTERY_TYPE, val);
};
// BATTERY_VOLTAGE_REALTIME
let batteryVoltageFormat = val => {
  return findLabelByValue(BATTERY_VOLTAGE_REALTIME, val);
};
let batteryVoltageColorFormat = val => {
  return findLabelByValue(BATTERY_VOLTAGE_REALTIME, val, "color");
};
let chargeCurrentFormat = val => {
  return findLabelByValue(CHARGE_CURRENT_REALTIME, val);
};
let chargeCurrentColorFormat = val => {
  return findLabelByValue(CHARGE_CURRENT_REALTIME, val, "color");
};
let batteryGroupOwnerFormat = val => {
  return findLabelByValue(BATTERY_GROUP_OWNER, val);
};

const pileInfoItemList = ref([
  {
    label: "枪归位",
    field: "connectorReturn",
    unit: "",
    formatter: connectorReturnFormat,
  },
  {
    label: "今日充电量",
    field: "chargePower",
    unit: "度",
  },
  {
    label: "今日充电时长",
    unit: "",
    field: "chargeDuration",
  },
  {
    label: "故障告警",
    unit: "",
    default: "无",
    field: "hardwareFail",
    color: "rgba(223, 55, 55, 1)",
  },
  {
    label: "今日充电订单",
    unit: "笔",
    field: "chargeQty",
  },
  {
    label: "今日在线率",
    unit: "",
    field: "onlineRate",
  },
]);

const route = useRoute();
const router = useRouter();
const {id: routeId} = route.params;
const currentConnectorCode = ref(routeId);

let activities = ref([]);

let stationTree = ref([]);
getPileTree().then(res => {
  console.log(res);
  stationTree.value = [...res.data];
});

let connectorStatFormat = computed(() => {
  if (headerData.value.pileStat === 2) {
    return findValue(CONNECTOR_STAT, 999);
  }
  return findValue(CONNECTOR_STAT, headerData.value.connectorStat) || {};
});

let currentTab = ref("first");

function handleClick(tab, event) {
}

// 图表管理

// 获取头部信息
let headerData = ref({});
let isDc = ref(false);

function getHeaderBarInfo(params) {
  getStatMonitorDetail(params).then(res => {
    headerData.value = res.data;
    isDc.value = res.data.pileType === 1;
  });
}

let filterInput = ref(dayjs().format("YYYY-MM-DD"));
let firstEnterFlag = true;

function getleftList() {
  getStatMonitorList({
    connectorCode: currentConnectorCode.value,
    dateStr: filterInput.value,
  }).then(res => {
    activities.value = [...res.data];

    if (firstEnterFlag && activities.value.length > 0) {
      firstEnterFlag = false;
      handleTimelineClick(activities.value[0]);
    }
  });
}

const {
  startPolling: headerDataStartPolling,
  stopPolling: headerDataStopPolling,
} = usePolling(
    () => {
      getleftList();
      getHeaderBarInfo({connectorCode: currentConnectorCode.value});
    },
    15000,
    true,
);

headerDataStopPolling();
headerDataStartPolling();

function handleDateFilterChange(val) {
  firstEnterFlag = true;

  //重置状态
  activatedIndex.value = null;
  activatedRow.value = {};
  getleftList();
}

let dateFilterOptions = ref({
  disabledDate(time) {
    return time.getTime() > Date.now();
  },
});

let stationPopoverRef = ref(null);

function handleGunChange(val) {
  stationPopoverRef.value.doClose();
  if (val && Array.isArray(val) && val.length > 0) {
    currentConnectorCode.value = val[val.length - 1];
    firstEnterFlag = true;
    activatedRow.value = {};
    getHeaderBarInfo({connectorCode: val[val.length - 1]});
    getleftList();
  }
}

// 轮询器
let activatedIndex = ref(null);
let activatedRow = ref(null);
const {startPolling, stopPolling} = usePolling(
    () => {
      if (activatedRow.value.stat === 3) {
        getChargeInfo(activatedRow.value.orderNumber);
        getBatteryInfo(activatedRow.value.orderNumber);
      } else {
        stopPolling();
      }
    },
    15000,
    true,
);

function handleTimelineClick(row) {
  console.log(row.startTime + row.stopTime + row.stat !== activatedIndex.value);

  if (row.startTime + row.stopTime + row.stat !== activatedIndex.value) {
    activatedIndex.value = row.startTime + row.stopTime + row.stat;
    chargeInfoData.value = {};
    batteryInfo.value = {};
    activatedRow.value = row;
    stopPolling();
    startPolling();
  }
}

function handleDetailClick(row) {
  if (row.stat === 3) {
    handleTimelineClick(row, row.startTime + row.stopTime + row.stat);
    return;
  }
  let timeRange = [];
  if (row.startTime && row.startTime) {
    timeRange = [dayjs(row.startTime).format('YYYY-MM-DD'), dayjs(row.stopTime).format('YYYY-MM-DD')];
  } else {
    timeRange = [dayjs(row.startTime).format('YYYY-MM-DD'), dayjs(row.startTime).format('YYYY-MM-DD')];
  }
  router.push({
    name: 'alarmManagementIndex',
    params: {
      orderNumber: row.orderNumber,
      timeRange,
    },
  });

}

// 充电模式(1:恒压充电 2:恒流充电)
let chargeModeDic = {
  1: "恒压充电",
  2: "恒流充电",
};
let chargeInfoFormList = computed(() => {
  return [
    {
      label: "已充电量",
      field: "chargePower",
      unit: "度",
    },
    {
      label: "充电模式",
      field: "chargeMode",
      unit: "",
      formatter(val) {
        return chargeModeDic[val] || "-";
      },
    },
    {
      label: "充电金额",
      field: "chargeAmount",
      unit: "元",
    },
    {
      label: "电桩输出电压",
      field: "connectorReturn",
      unit: "",
      formatter(val, row) {
        return `最高: ${row.pileOutVoltageMax ||
        "-"}V/最低: ${row.pileOutVoltageMin || "-"}V`;
      },
    },
    {
      label: "充电时长",
      field: "chargeDuration",
      unit: "",
    },
    {
      label: "电桩输出电流",
      field: "connectorReturn",
      unit: "",
      formatter(val, row) {
        return `最高: ${row.pileOutCurrentMax ||
        "-"}A/最低: ${row.pileOutCurrentMin || "-"}A`;
      },
    },
    {
      label: "剩余时长",
      field: "surplusDuration",
      unit: "",
      hidden: !isDc.value,
    },
  ];
});
let carerInfoFormList = computed(() => {
  return [
    {
      label: "车主手机",
      field: "phone",
      unit: "",
    },
    {
      label: "车牌号",
      field: "userCarNo",
      unit: "",
      formatter: carFilter,
    },
    {
      label: "品牌型号",
      field: "userCarInfo",
      unit: "",
    },
    {
      label: "VIN码",
      field: "userCarVin",
      unit: "",
    },
    {
      label: "VIN码 (BMS上传)",
      field: "bmsVin",
      unit: "",
      hidden: !isDc.value,
    },
  ];
});
let batteryInfoFormList = ref([
  {
    label: "通信协议版本",
    field: "protocolVersion",
    unit: "",
    prefix: "",
  },
  {
    label: "电池生产日期",
    field: "bpPd",
    unit: "",
  },
  {
    label: "BMS软件版本",
    field: "softwareVersion",
    unit: "",
  },
  {
    label: "BMS最高允许电流",
    field: "ovrcurr",
    unit: "A",
  },
  {
    label: "电池额定容量",
    field: "ratedCapacity",
    unit: "Ah",
  },
  {
    label: "电池充电次数",
    field: "bpChargeFreq",
    unit: "次",
  },
  {
    label: "BMS最高允许温度",
    field: "mat",
    unit: "°C",
  },
  {
    label: "BMS最高允许电压",
    field: "vcc",
    unit: "V",
  },
  {
    label: "电池额定电压",
    field: "ratedTotalVoltage",
    unit: "V",
  },
  {
    label: "电池产权标识",
    field: "bpIp",
    unit: "",
    formatter(val) {
      return batteryGroupOwnerFormat(val);
    },
  },
  {
    label: "BMS标称总能量",
    field: "totalEnergy",
    unit: "度",
  },
  {
    label: "单体最高允许电压",
    field: "umax",
    unit: "V",
  },
]);
let chargeInfoData = ref({});

function getChargeInfo(orderNumber) {
  getStatMonitorOrder({
    orderNumber,
  }).then(res => {
    chargeInfoData.value = res.data;
    initSocChart(chargeInfoData.value.orderMonitorList);
    initPowerChart(chargeInfoData.value.orderMonitorList);
    if (isDc.value) {
      initCurrentChart(chargeInfoData.value.orderBmsReqOutList);
      initVoltageChart(chargeInfoData.value.orderBmsReqOutList);
    } else {
      initCurrentChart(chargeInfoData.value.orderMonitorList);
      initVoltageChart(chargeInfoData.value.orderMonitorList);
    }

    initConnectorLineTempChart(chargeInfoData.value.orderMonitorList);
    initBpMaxTempChart(chargeInfoData.value.orderMonitorList);
  });
}

let batteryInfo = ref({});
let batteryStopReason = computed(() => {
  let arr = [];
  batteryInfo.value.bmsIntCause && arr.push(batteryInfo.value.bmsIntCause);
  batteryInfo.value.bmsIntErrorCause &&
  arr.push(batteryInfo.value.bmsIntErrorCause);
  batteryInfo.value.bmsIntFaultCause &&
  arr.push(batteryInfo.value.bmsIntFaultCause);
  batteryInfo.value.obcIntCause && arr.push(batteryInfo.value.obcIntCause);
  batteryInfo.value.obcIntErrorCause &&
  arr.push(batteryInfo.value.obcIntErrorCause);
  batteryInfo.value.obcIntFaultCause &&
  arr.push(batteryInfo.value.obcIntFaultCause);
  return arr.join("/");
});

function getBatteryInfo(orderNumber) {
  getStatMonitorBattery({
    orderNumber,
  }).then(res => {
    batteryInfo.value = res.data;
  });
}

// 初始化图表
const {
  getChartSOCOption,
  getPowerLineOption,
  getCurrentLineOption,
  getVoltageLineOption,
  getConnectorLineTempOption,
  getBpMaxTempOption,
} = useChartOption();

let isSocChartNull = ref(true);
let socChartOption = ref(null);

function initSocChart(data) {
  isSocChartNull.value = data.length === 0;
  socChartOption.value = getChartSOCOption(data, {isDc: isDc.value});
}

let isPowerChartNull = ref(true);
let powerChartOption = ref(null);

function initPowerChart(data) {
  isPowerChartNull.value = data.length === 0;
  powerChartOption.value = getPowerLineOption(data);
}

let isCurrentChartNull = ref(true);
let currentChartOption = ref(null);

function initCurrentChart(data) {
  isCurrentChartNull.value = data.length === 0;
  currentChartOption.value = getCurrentLineOption(data, {isDc: isDc.value});

}

let isVoltageChartNull = ref(true);
let voltageChartOption = ref(null);

function initVoltageChart(data) {
  isVoltageChartNull.value = data.length === 0;
  voltageChartOption.value = getVoltageLineOption(data, {isDc: isDc.value});
}

let isConnectorLineTempChartNull = ref(true);
let connectorLineTempChartOption = ref(null);

function initConnectorLineTempChart(data) {
  isConnectorLineTempChartNull.value = data.length === 0;
  connectorLineTempChartOption.value = getConnectorLineTempOption(data);
}

let isBpMaxTempChartNull = ref(true);
let bpMaxTempChartOption = ref(null);

function initBpMaxTempChart(data) {
  isBpMaxTempChartNull.value = data.length === 0;
  bpMaxTempChartOption.value = getBpMaxTempOption(data);
}
</script>

<style lang="scss" scoped>
@mixin flex($direction: row, $align-items: flex-start) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
}

.main {
  height: 100%;
  padding: 0 !important;
  overflow-x: scroll;

  .main-scroll-container {
    min-width: 1430px;
    height: 100%;
    gap: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .main-info-box {
    @include flex(row, flex-start);
    align-self: stretch;
    border-radius: 8px;
    background: var(--PC-, #fff);
  }

  .main-info-box-left {
    @include flex(column, center);
    width: 180px;
    padding: 24px 0;
    justify-content: center;
    gap: 16px;
    border-radius: 8px;
    border: 4px solid #fff;
    height: 100%;
    background: rgba(8, 171, 103, 0.04);
  }

  .main-info-box-right {
    display: flex;
    padding: 16px 16px 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .main-info-descriptions {
    @include flex(row, flex-start);
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;
  }

  .text-descriptions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
  }

  .main-content-container {
    @include flex(row, flex-start);
    align-self: stretch;
    background: var(--PC-, #fff);
    border-radius: 8px;
    flex: 1;
    height: 100%;
    min-height: 0;
  }

  .main-content-container-left {
    display: flex;
    width: 250px;
    padding: 16px 0 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .timeline-container {
      display: flex;
      overflow-y: scroll;
      overflow-x: hidden;
      flex: 1;
      width: 100%;
      position: relative;
    }
  }

  .main-content-container-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
    min-width: 0;
    overflow: scroll;

    .el-tabs {
      flex: 1;
      width: 100%;
      min-width: 0;
      margin-right: 0;

      ::v-deep .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #e5e6eb;
      }

      ::v-deep .el-tabs__header {
        margin-left: 16px;
      }
    }

    .tab-content-container {
      display: flex;
      padding: 0 16px 16px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      align-self: stretch;
      width: 100%;
      min-width: 0;
    }

    .box-container {
      display: flex;
      padding: 12px;
      align-items: stretch;
      gap: 12px;
      align-self: stretch;
      border-radius: 8px;
      border: 3px solid rgba(255, 255, 255, 0.85);
      background: linear-gradient(91deg, #ebf5f7 0.07%, #f5f2f0 100%);

      .box-mini-title {
        color: var(--PC-, #1d2129);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .box-content {
        display: flex;
        width: 100%;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 8px;
        border: var(--border-radius-small, 2px) solid #fff;
        background: rgba(255, 255, 255, 0.65);

        &-left {
          width: 60%;
        }

        &-right {
          width: 40%;
        }
      }
    }

    .info-card-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px;
      align-self: stretch;
      flex-wrap: wrap;

      .info-row-item {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        &.info-row-item-right {
          min-width: 210px;
        }

        .info-row-label {
          color: var(--PC-, #86909c);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-wrap: nowrap;
          // min-width: 56px;
        }

        .info-row-content {
          color: var(--PC-, #1d2129);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.text-title {
  overflow: hidden;
  color: var(--PC-, #1d2129);
  text-overflow: ellipsis;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.progress-container {
  position: relative;

  ::v-deep .el-progress__text {
    font-size: 14px !important;
    font-weight: 500;
  }

  .right-bottom-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 24px;
    flex-shrink: 0;
  }
}

.chart-box-layer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
  flex-wrap: wrap;
  min-width: 0;

  .chart-box {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--PC--, #e5e6eb);
    display: flex;
    width: 100%;
    min-height: 0;
    min-width: 40%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Space-Padding-paddingSM, 12px);
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    color: var(--PC-, #1d2129);
    font-weight: 500;
    line-height: normal;
  }

  .chart-box-title {
    margin: 16px 0 0 16px;
  }
}

.car-info-panel {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: 8px;
  border: var(--border-radius-small, 2px) solid var(--PC-, #fff);
  background: #fff;

  .info-car-card {
    background-color: #f8fafb;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 440px;
    flex-shrink: 0;
    min-width: 650px;

    .info-car-image {
      position: relative;
    }

    .info-car-card-bubble-layout {
      display: flex;
      padding: 8px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      border-radius: 8px;
      border: var(--border-radius-small, 2px) solid #fff;
      background: #fff;
      box-shadow: 0px 4px 16px 0px rgba(29, 33, 41, 0.08);
    }

    .info-car-card-bubble-lt {
      position: absolute;
      left: 117px;
      bottom: 248px;
    }

    .info-car-card-bubble-value {
      color: var(--PC-, #057c98);
      font-family: D-DIN-PRO;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }

    .info-car-card-bubble-label {
      color: var(--PC-, #4e5969);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 183.333% */
    }

    .info-car-card-bubble-lt-arrow {
      position: absolute;
      width: 40px;
      height: 20px;
      right: 14px;
      bottom: -26px;
      transform: rotateY(180deg) rotate(283deg);
    }

    .info-car-card-bubble-rt {
      position: absolute;
      right: 165px;
      bottom: 290px;
    }

    .info-car-card-bubble-rt-arrow {
      position: absolute;
      width: 40px;
      height: 20px;
      transform: rotate(274deg);
      left: 0;
      bottom: -24px;
    }

    .info-car-card-bubble-lb {
      position: absolute;
      left: 252px;
      top: 286px;
    }

    .info-car-card-bubble-lb-arrow {
      position: absolute;
      width: 40px;
      height: 20px;
      transform: rotate(274deg);
      left: 5px;
      top: -24px;
      transform: rotateY(180deg) rotate(92deg);
    }

    .info-car-card-bubble-rb {
      position: absolute;
      left: 436px;
      top: 203px;
    }

    .info-car-card-bubble-rb-arrow {
      position: absolute;
      width: 40px;
      height: 20px;
      transform: rotate(354deg);
      left: -28px;
      top: 14px;
    }
  }

  .info-card-box {
    width: 400px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    // gap: 10px;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-end;

    .info-row-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.right-panel-tab-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.text-descriptions-label {
  color: var(--PC-, #86909c);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-descriptions-content {
  color: var(--PC-, #1d2129);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .el-timeline-item__tail {
  position: absolute;
  left: 7px;
  top: 16px;
  height: 80%;
  border-left: 1px solid #e4e7ed;
}

::v-deep .el-timeline-item {
  padding-bottom: 4px;
}

::v-deep .el-timeline-item__node::before {
  content: "";
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: #c9cdd4;
  border-radius: 50%;
}

::v-deep .el-timeline-item__node-- {
  content: "";
  flex-shrink: 0;
  background: transparent;
  width: 14px;
  height: 14px;
  left: 0;
  top: -2px;
  border-radius: 50%;
}

::v-deep .el-timeline-item__node--large::before {
  content: "";
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: var(--current-color);
  border-radius: 50%;
}

::v-deep .el-timeline {
  width: 100%;
  padding-top: 2px;
}

.progress-text {
  color: var(--PC-, #057c98);
  text-align: center;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.empty-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 0 16px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(29, 33, 41, 0.02);
}

.station-switch {
  margin-left: 8px;
  font-size: 20px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: rgba(29, 33, 41, 0.08);
    border-radius: 6px;
  }
}
</style>
